.container-add-new-account {
  display: flex;
  justify-content: flex-end;
  .add-new-account {
    border-radius: 10px !important;
    font-weight: 700;
    width: 150px;
  }
}

.container-account-selected {
  background-color: rgba(157, 157, 157, 0.15);
  border: 2px solid rgba(0, 0, 0, 0.15);
  padding: 5%;
  margin-bottom: 10%;
  border-radius: 10px !important;
  .account-selected {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
  .account-selected-button {
    border-radius: 4px !important;
    font-weight: 700;
    width: 150px;
    margin-top: 30px;
  }
  .account-selected-email {
    font-style: normal;
    font-weight: 600;
    color: #000000;
  }
  .account-log-out-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-top: 30px;
    }
  }
}

.border-color-container {
  border: 2px solid rgb(141 54 54 / 55%) !important;
}

.img-gmail {
  height: 40px;
  width: 80px;
  margin-left: 4px;
  margin-right: 20px;
}

.img-zoho {
  height: 30px;
  width: 80px;
  margin-left: 4px;
  margin-right: 20px;
}

.img-outlook {
  height: 32px;
  width: 35px;
  margin-left: 18px;
  margin-right: 20px;
}
