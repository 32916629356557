@use "variables" as variables;
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

@import "bulma";
@import "~rbx/rbx";

$menu-width: 295px;

.principal-container {
  display: flex;
  justify-content: center;
}
.content-right {
  justify-content: flex-end;
  text-align: center;
  width: 30%;
}
.content-flex-end {
  justify-content: flex-end;
  display: flex;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}

.break-text {
  word-break: break-all;
}

.evenly {
  @extend .flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
}

.container-header {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.middle {
  justify-content: center;
  align-items: center;
}

.align-left {
  float: inline-end;
  position: absolute;
  left: 0rem;
  top: 0rem;
  color: white;
}

.align-right {
  right: 0;
  top: 0;
  position: absolute;
}

.card-total-width {
  width: 95%;
}

.container-padding {
  margin: 1rem;
  padding-top: 1rem;
}

img {
  height: 100%;
  width: 100%;
}
table {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.modal-content {
  background-color: variables.$grey-font;
  border-radius: 7px;
}

.content-left {
  justify-content: flex-start;
  text-align: flex-start;
  width: 70%;
}

.card-top {
  padding: 1rem;
}

.card-content {
  background-color: #f5f9ff;
  border: 0.5px solid variables.$primary-palette;
  border-radius: 7px;
  color: variables.$primary-palette;
  padding: 0.5rem;
  margin-top: 1rem;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
  width: 100%;
  max-height: 100%;
}

.card-summary {
  background-color: #f5f9ff;
  border: 0.5px solid variables.$primary-palette;
  border-radius: 7px;
  color: variables.$primary-palette;
  padding: 0;
  margin-top: 1rem;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
  width: 100%;

  .card-secondary-content {
    background-color: variables.$primary;
    border: 0.5px solid variables.$primary-palette;
    border-radius: 7px;
    color: variables.$primary-palette;
    padding: 0.3rem;
    width: 100%;
    margin: 0;
    border-start-start-radius: 0.2rem;
    border-start-end-radius: 0.2rem;

    span,
    h4,
    h3 {
      color: white;
    }
  }
}
.profilepic {
  position: relative;
  width: 125px;
  height: 125px;
  // border-radius: 50%;
  overflow: hidden;
  background-color: #111;
  border-radius: 7px;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  height: 25%;
  background: rgba(245, 249, 255, 0.6);
  border-bottom: 7px;
}
.profilepic__content:hover {
  opacity: 1;
}

.profilepic__icon {
  color: white;
  padding-bottom: 8px;
}

.fas {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}
.card-products {
  background-color: variables.$primary;
  border: 0.5px solid variables.$primary-palette;
  border-radius: 7px;
  color: variables.$primary-palette;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  span,
  h4,
  h3 {
    color: white;
  }
}

.tag:not(body).is-primary {
  background-color: variables.$primary-palette;
}

button {
  border-radius: 0.7rem !important;
}
.button {
  background-color: transparent;
  color: variables.$white-palette;
  border: variables.$white-palette 0.1rem solid;
}
.card-button:hover {
  color: #a9a9a9;
}

.card-button:active {
  color: #a9a9a9;
}

.card-button:focus {
  color: #a9a9a9;
}
.input {
  border: 0.1rem solid variables.$primary;
  height: 40px;
}

.items-centered {
  display: flex;
  align-items: center;
}

.phone-input {
  border: 0.1rem solid variables.$primary;
  display: flex;
  border-radius: 4px;

  .input {
    border: none;
    box-shadow: none;
    height: initial;
  }
  .select-phone {
    width: 150px;
    border: none;
    border-right: 0.1rem solid variables.$primary;
  }
  &-icon {
    @extend .items-centered;
  }
  &-flag {
    height: 20px;
    width: auto;
    margin-right: 10px;
  }
}

a {
  color: variables.$white-palette;
  text-align: center;
}
.input::placeholder {
  color: $black-ter;
}

.is-outlined {
  border-radius: 25px 25px 25px 25px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.primary-title {
  background-color: variables.$primary;
  color: variables.$white-palette;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.card-button:hover {
  color: #a9a9a9;
}

.card-button:active {
  color: #a9a9a9;
}

.card-button:focus {
  color: #a9a9a9;
}

.secondary-button {
  background: none;
  border: none;
  cursor: pointer;

  //letter-spacing: 0.05rem;
}
.edit-icon {
  top: 0.5rem;
  color: #01234c;
  position: absolute;
  right: 3rem;
}

button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

button::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.button.is-secondary {
  background-color: transparent;
  border: 2px solid variables.$primary-palette;
  color: variables.$primary-palette;
  margin-right: 1rem;
  cursor: pointer;
  &.m-0 {
    margin-right: 0;
  }
}
.button.is-primary {
  background-color: #01234c;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}
.button.is-primary:hover {
  background-color: #002d64;
}
.button.is-primary:disabled {
  background-color: #01234c;
}
.button.is-primary:focus {
  background-color: #01234c;
}
.button.is-primary.is-outlined {
  border-color: variables.$primary;
  color: variables.$primary;
}
.button.is-primary.is-outlined:hover {
  background-color: #002d64;
  border-color: #002d64;
  color: variables.$white-palette;
}
.button.is-primary.is-outlined:disabled {
  border-color: #01234c;
  color: #01234c;
}
.button.is-primary.is-outlined:focus {
  background-color: #01234c;
}
button.is-secondary::after {
  background-color: #fff;
}

button,
input,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p,
select,
Label,
table {
  font-family: "PT Sans", sans-serif;
}
h1 {
  font-size: 1.5rem;
  font-weight: bold;
}
h2 {
  font-size: 1.2rem;
  font-weight: bold;
}
h3 {
  font-size: 1.1rem;
}
h4 {
  font-size: 0.8rem;
}
p {
  font: size 0.5rem;
  margin-right: 1rem;
}

.title-center {
  justify-content: center;
  display: flex;
  font-size: 1.2em;
}

.has-text-white {
  color: variables.$white-palette !important;
}

@media screen and (min-width: 1024px) {
  main.container {
    max-width: 100%;
    font-family: "PT Sans", sans-serif;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.8rem;
    padding: 1rem;
    grid-row-gap: 0.8rem;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
  }
}
@media screen and (max-width: 1024px) and (min-width: 721px) {
  main.container {
    max-width: 100%;

    font-family: "PT Sans", sans-serif;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.8rem;
    padding: 1rem;
    grid-row-gap: 0.8rem;
  }
}

@media screen and (min-width: 769px) {
  main.container {
    max-width: 100%;

    font-family: "PT Sans", sans-serif;

    margin-left: 0px !important;

    .main-page-container {
      transition: padding 0.3s linear;
      padding-left: 2rem;
    }
    &.open {
      // width: calc(100% - 295px) !important;
      .main-page-container {
        padding-left: calc(2rem + 295px) !important;
      }
      nav {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.main-page-container {
  padding-top: 60px;
}

@media screen and (max-width: 900px) {
  .main-page-container {
    font-family: "PT Sans", sans-serif;

    &.open {
      opacity: 0.5;
    }
  }
}

.section-container {
  min-height: calc(100vh - 3.25rem);
  padding: 4rem 0 1.5rem 0;
  width: 100%;
  .section-header {
    display: flex;
    flex-direction: row;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #059e42;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Para 941px a 1024px */
@media only screen and (min-width: 941px) and (max-width: 1024px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
  }
}
/* Para 801px a 940px */
@media only screen and (min-width: 801px) and (max-width: 940px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 722px a 800px */
@media screen and (max-width: 800px) and (min-width: 722px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 621px a 721px */
@media screen and (max-width: 721px) and (min-width: 621px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 461px a 620px */
@media only screen and (max-width: 620px) and (min-width: 461px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 361 a 460px */
@media only screen and (max-width: 460px) and (min-width: 361px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 5px a 360px */
@media only screen and (max-width: 360px) and (min-width: 5px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }
  .grid-small {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.5rem;
  }
  .grid-top {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: auto;
    height: auto;
    white-space: normal;
    word-break: keep-all;
    text-align: center;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.form-inputs {
  select {
    border: 0.1rem solid variables.$primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    height: 37px;
  }
  border-radius: 0.5rem;
  text-align: center;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 5rem;
  margin-left: 5rem;
  justify-content: center;
}

/*  5px - 820px */
@media only screen and (max-width: 820px) and (min-width: 5px) {
  .form-inputs {
    margin-right: 0;
    margin-left: 0;
  }
}

.is-text-breaker {
  word-break: break-word;
}

.is-clickable {
  cursor: pointer !important;
}
.input-height {
  height: 40px !important;
}

.head-text {
  font-size: 1rem;
  font-weight: bold;
  text-align: center !important;
  color: white;
}
