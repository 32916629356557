@use "../../../../styles/variables" as *;

.edit-container {
  select {
    border: 0.1rem solid $primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 4px;
    height: 39px;
  }
}
