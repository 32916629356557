@use "../../styles/variables" as *;

.title-center {
  justify-content: center;
  display: flex;
  font-size: 1.2em;
}

.right-content {
  background-color: white;
  width: 50%;
  padding: 20px 20px 20px 0;
  Button {
    margin-right: 0.5rem;
  }
  select,
  input {
    border: 0.1rem solid #b4c8e0;
  }
}

.receiver-information {
  textarea {
    border: 0.1rem solid #b4c8e0;
  }
}

.letf-bottom-content {
  width: 120%;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 0;
  textarea {
    border: 0.1rem solid #b4c8e0;
  }
}

.left-content {
  width: 120%;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 0;
  select,
  input {
    border: 0.1rem solid #b4c8e0;
  }
}

.container-content {
  display: flex;
  .control {
    margin-right: 1%;
  }
}
.receiver-address {
  display: flex;

  .select {
    width: 12rem;
  }
}
.bottom-content {
  padding-top: 1rem;
  margin-top: 1rem;
  border-color: #5480b5;
  border-top: 1px solid;
  select {
    border: 0.1rem solid #b4c8e0;
  }
}
.totals-content {
  width: 30%;
}

.body-bottom-content {
  display: flex;
}

.identification-field {
  display: flex;

  input,
  select {
    margin-right: 20px;
  }
}

.detail-selector {
  display: flex;
  max-width: 100%;
  .control {
    margin-right: 1%;
    max-width: 100%;
    width: 100%;
  }
}

.notes-content {
  width: 24%;
  textarea {
    border: 0.1rem solid #b4c8e0;
  }
}
.left-content-totals {
  width: 38%;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 0;
  Label,
  input {
    font-size: 13px;
    width: 50%;
  }
  input {
    margin-right: 1rem;
    height: 12%;
    border: 0.1rem solid #b4c8e0;
  }
}

.receiver-actions {
  margin-top: 2rem;
}

.buttons-detail {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5%;
  button {
    width: 100%;
  }
}

.hover-table-options {
  &:hover {
    color: gray;
    cursor: pointer;
  }
}

.right-cell {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.button-styles {
  background-color: #5480b5;
}
.button-secondary-styles {
  border: 0.1rem solid #5480b5;
  border-color: #5480b5;
  color: #5480b5;
  margin-right: 1rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0.7;
  filter: invert(0);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}
