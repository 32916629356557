.container-button-new-account {
  display: flex;
  justify-content: center;
}

.hide-icon {
  display: none;
}

.icon-right {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.icon-left {
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.title-new-account {
  font-size: 20px;
}
