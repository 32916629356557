@use '../../styles/variables' as *;
.confirmation-modal {
  display: flex;
  flex-direction: column;
  &__header{
    margin:10px 0px;


  }
  &__body{
    margin:10px 0px;
      
  }
  &__footer{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin:10px;
  }
  &__button{
      width: 40%;
  }
  &__header-text{
      font-size: 30px;

      &--normal{
          font-size: 20px;
      }
      &--bold{
          font-weight: bold;
          color:$primary
      }
  }
}
