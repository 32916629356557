@use "../../styles/variables" as *;

.CardConteiner {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  flex-wrap: wrap;
}
.together-elements-container {
  width: 400px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cardSumary {
  padding: 1rem;
}
.csvlink {
  color: #dbdbdb;
  background-color: #01234c;
  border: 1px solid #01234c;
  padding: 0.5rem;
  min-height: 42px;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  &:hover,
  &:focus {
    color: #fff;
    border: 1px solid #dbdbdb;
  }
}

.Report-genered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
}

.card-store {
  background-color: #dbdbdb;
  border: 1px solid #01234c;
  border-radius: 5px;
  min-width: 200px;
  min-height: 60px;
  max-width: 300px;
  margin-top: 0.5em;
  &:hover {
    cursor: pointer;
    border: 1px solid #dbdbdb;
    background-color: #cfcfcf;
  }
}

.card-header-store {
  background-color: #01234c;
  border-radius: 5px;
  padding-left: 0.4rem;
}

.card-body-store {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
}

.card-header-text {
  color: #fff;
  font-weight: 700;
}

.card-store-icone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  height: 100%;
  padding-left: 1rem;
}

.card-store-information {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
}

.Card-summary {
  padding: 1rem;
}

.CSV-section {
  display: flex;
  justify-content: flex-end;
}

.summary-download {
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.datePicker-sellReport {
  min-width: 200px;
  min-height: 36px;
  max-width: 300px;
  font-size: 16px;
  padding-left: 0.5rem;
  text-transform: capitalize;
  border: solid 1px $primary;
  border-radius: 0.7rem !important;
  margin-right: 0.5em;
}

.react-datepicker__month-text {
  text-transform: capitalize;
}

.no-results {
  text-align: center;
  color: #dbdbdb;
}
