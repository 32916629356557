.productsReport {
  &-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    position: relative;
  }
  &-loading {
    height: 100%;
    width: 100%;
    bottom: 5;
    position: absolute;
  }
}
.productsReport-link {
  color: #363636;
}
