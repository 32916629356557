@use "../../styles/variables" as *;

.billing {
  &-input {
    max-width: 500px;
    width: 90%;
    margin: 10px;
  }
}

.input:disabled{
  background: white;
}

.container-receiverReceiver {
  width: 100%;
  input,
  select,
  textarea {
    margin-right: 20px;
    border: 0.1rem solid #b4c8e0;
  }
}

.identification-field-receiver {
  display: flex;
  .control {
    width: 100%;
    margin-right: 0.7%;
  }

  input,
  select,
  textarea {
    border: 0.1rem solid #b4c8e0;
  }
}
