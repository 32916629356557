.Container-container {
  background-color: #f5f9ff;
  border: 0.5px solid #01234c;
  border-radius: 7px;
  color: #01234c;
  margin-top: 1rem;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
}

.container-head-button {
  margin-top: 0.5rem;
  min-height: 50px;
  padding: 10px 20px;
}

.head-new-expense {
  background-color: #01234c;
  min-height: 30px;
}

.title-new-expense {
  padding: 0.4rem;
  color: #f5f9ff;
}

.buyElectronic-button {
  margin-right: 1rem;
}

.expense_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-top: 1rem;
}

.select-iva {
  min-width: 205px;
}

.discount-buy,
.amount-buy {
  padding-right: 1rem;
}

.div-button-buy {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.summary-container,
.buy-textarea {
  padding: 1rem;
}

.head-summary {
  display: flex;
}

.fisrt-head {
  width: 100%;
}

.second-head {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.label-descuento {
  padding-right: 5rem;
}

.container-total-summary {
  display: flex;
  background-color: #01234c;
  min-height: 30px;
}

.container-total-summary > p,
container-total-summary-expenses > p {
  color: #f5f9ff;
  padding: 0.4rem;
}

.container-total-summary-expenses {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.container-total-summary-expenses > p {
  text-align: center;
  min-width: 80px;
  color: #f5f9ff;
  padding: 6.4px;
}

.all-text {
  font-weight: 700;
}

.footer-summary-first {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.column-amount {
  display: flex;
  min-width: 200px;
}

.text-amount {
  text-align: right;
  width: 100%;
  padding-left: 0.3rem;
}

.text-iva {
  padding-left: 1rem;
}

.text-totals {
  text-align: center;
}

.container-expenses-card {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px;
}

.buttons-proveedor{
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .container-expenses-card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .actions-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
  .container-head-button {
    margin-top: 0.5rem;
    min-height: 50px;
    padding: 10px 20px;
    font-size: 18px !important;
  }
}
