.CardProductContainer {
  max-width: 430px;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.CardProductConteinerButtons {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.CardProductBody {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
}

.CardProductBodyRight {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  width: 30%;
}

.delete-button{
  background: transparent; color: #ff3860; border: solid 0.5px;
}
