@use "../../styles/variables" as *;

.icon-download {
  margin-top: -1.5rem;
  color: $primary;
  position: absolute;
  right: 0.2rem;
}

.client-report {
  width: 100%;
}

.download-buttons {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.button-download {
  margin-left: 5px;
}
