@use "../../styles/variables" as *;

.card-content-client {
    background-color: #f5f9ff;
    border: 0.5px solid $primary-palette;
    border-radius: 5px;
    color: $primary-palette;
    padding: 0.5rem;
    margin-top: 1rem;
    width: 100%;
    max-height: 100%;
  }