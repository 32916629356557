@use "../../styles/variables" as *;

.CropperPhoto {
  &-container {
    justify-content: center;
    position: relative;
  }
  &-hide {
    display: none;
  }
  &-button {
    margin-left: 1rem;
    &-container {
      position: relative;
      justify-content: center;
      display: flex;
      margin: 1rem;
    }
  }
  &-loader-container {
    display: flex;
    justify-content: center;
  }
}
.input-file {
  display: none;
}
