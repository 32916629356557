.SearchProduct {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.headerButton {
  padding-bottom: 0.5rem;
}

.textSearch {
  font-size: 18px;
}

.textResult {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
