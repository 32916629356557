@use '../../styles/variables' as *;

.draft-bill {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 6px solid $primary;
  border-radius: 3px;
  &__header {
    background-color: $primary;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0px;
    padding: 5px;
  }
  &__header-text {
    color: #ffffff;
    margin: auto 0px;
    font-size: 25px;
    font-weight: normal;
    margin-right: 10px;

    &--bold {
      font-weight: bold;
    }
  }
  &__footer {
    margin: 10px;
    gap: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
  &__content {
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  &__image-container {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  &__image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  &__detail-text {
    margin: auto 0px;
    font-weight: normal;
    
    &--bold {
      font-weight: bold;
    }
  }
  &__button {
    margin: 0px !important;
    width: 100px;
  }
}
