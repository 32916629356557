@use "../../styles/variables" as *;

.profile-page-container {
  select {
    border: 0.1rem solid $primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    height: 37px;
  }
}
.photo-content {
  margin-bottom: 1rem;
  border: 2px solid $primary-palette;
  border-radius: 1rem;
  color: $primary-palette;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  justify-content: center;
  padding-bottom: 0.5rem;
  overflow: hidden;
}
.profile-page-preview {
  width: 100px;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 1rem;
  justify-content: center;
  border: 0.2rem solid $secondary-palette;
}

.profile-page-photo {
  margin: 0.2rem;
  border-radius: 1rem;
}

.profile-page-select-details {
  border: 0.1rem solid $primary;
  display: inline-block;
  border-radius: 0.5rem;
  height: 41px;
}

.profile-page-button {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.profile-page-title {
  justify-content: center;
  display: flex;
  margin-bottom: 0.5rem;
}

.icon-edit {
  margin-top: -0.5rem;
  color: $primary;
  position: absolute;
  right: -0.1rem;
}
