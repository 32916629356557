.client-details {
  margin-bottom: 0.5rem;
}
.client-header {
  width: 75%;
}
.client-footer {
  width: 25%;
  align-items: center;
}
