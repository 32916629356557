.expenses-management {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}
.expenses-management-title {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
