@use "../../../../styles/variables" as *;
.expenses-summary {
  width: 24%;
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 1400px) {
  .expenses-summary {
    width: 31%;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .expenses-summary {
    width: 48%;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  .expenses-summary {
    width: 90%;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

.card-content-amount {
  display: flex;
}

.head-card-expense {
  background-color: #01234c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
}

.title-card-expense {
  padding: 0.4rem;
  color: #f5f9ff;
}

.icon-delete {
  cursor: pointer;
  color: red;
}

.card-head-delete {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-head-title {
  width: 90%;
}

.card-expense-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.card-space-amount {
  font-size: 18px;
}
.card-space-total-amount {
  font-size: 18px;
  color: $primary;
}
