@use "../../styles/variables" as variables;

.product-info {
  width: 70%;
  display: flex;
  justify-content: flex-start;
}
.product-footer {
  display: flex;
  margin-bottom: 0.5rem;
}
.items-center {
  margin-top: 0.25rem;
}
.icon-cancel {
  margin-top: -0.5rem;

  color: variables.$primary;
  position: absolute;
  right: -0.1rem;
}
.product-base {
  display: flex;
  height: 15%;
  .input {
    height: 100%;
    width: 30%;
    margin-left: 0.5rem;
    color: variables.$primary;
    margin-right: 0.5rem;
  }
  .label{
   color: variables.$primary;
  }
}
.products-buttons {
  cursor: pointer;
}
.three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.one-line {
  white-space: nowrap;
}
.content-image {
  justify-content: flex-end;
  text-align: center;
  width: 15%;
  height: 10%;
}
@media screen and (min-width: 621px) {
  .product-header {
    justify-content: flex-start;
    height: 50%;
  }
  .details-product {
    font-size: 0.8rem;
  }
  .title-product {
    font-size: 1.1rem;
  }
  .ellipsis {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .product-card {
    background-color: #f5f9ff;
    border: 0.5px solid variables.$primary-palette;
    border-radius: 7px;
    color: variables.$primary-palette;
    margin-top: 0.2rem;
    padding: 0.5rem;
    border-start-start-radius: 0.2rem;
    border-start-end-radius: 0.2rem;
    width: 100%;
    max-height: 100%;
  }
}

/* Para 5px a 360px */
@media only screen and (max-width: 620px) and (min-width: 5px) {
  .title-product {
    font-size: 0.9rem;
    font-family: "PT Sans", sans-serif;
  }
  .details-product {
    font-size: 0.8rem;
  }
  .ellipsis {
    font-size: 0.8rem;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-header {
    justify-content: flex-start;
    height: 45%;
  }
}
.product-card {
  background-color: #f5f9ff;
  border: 0.5px solid variables.$primary-palette;
  border-radius: 7px;
  color: variables.$primary-palette;
  margin-top: 0.2rem;
  padding: 0.5rem;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
  width: 100%;
  max-height: 100%;
}
.carousel-container {
  margin-top: 2rem;
}
.carousel-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background: variables.$primary;
  border: 0.5px solid variables.$primary;
  border-radius: 5px;
}
.carousel-item-small {
  margin-left: 5rem;
  margin-right: 5rem;
  background: variables.$primary;
  border: 0.5px solid variables.$primary;
  border-radius: 5px;
}
.small {
  height: 10rem;
}
.selectables {
  display: flex;
  justify-content: flex-start;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  align-items: center;
  label {
    margin-left: 0.5rem;
    font-size: 120%;
  }
}
