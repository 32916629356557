$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 98%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(240, 100%, 100%);

$grey-font: #eaedf1;

$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 71%, 48%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$blue: hsl(217, 68%, 35%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);
$blue-med: #0c3c77;
$blue-med-faded: rgba(55, 87, 157, 0.5);
$blue-option: #040a1fc5;
$blue-l: hsl(210, 22%, 93%);

$primary: #01234c;
$secondary: #2b4a6f;
$background: #2b4a6f;
$text_primary: #B5E500;
// $tertiary: #e27f0b;

// $text: #333333;

// $warning: #fdd154;
$success: #7eb2c9;
// $danger: #e96f4c;
// $link: #7eb2c9;
// $info: #e27f0b;

//Palet
$white-palette: rgba(234, 237, 241);
$black-palette: rgba(0, 4, 9);
$primary-palette: rgba(1, 35, 76);
$secondary-palette: rgba(141, 143, 146);
