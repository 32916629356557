@use "../../styles/variables" as *;

table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: $primary;
  color: white;
}

.table_container {
  width: 100%;
  select {
    border: 0.1rem solid $primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 4px;
    height: 38px;
  }
  input {
    border: 0.1rem solid #01234c;
    height: 38px;
  }
  .td {
    text-align: center;
  }
}
