@use "../../styles/variables" as variables;

.reset-password {
  width: 100vw;
  height: 100vh;
  align-items: center;
  background-image: url(../../assets/login-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &-container {
    position: absolute;
    width: 90vw;
    max-width: 700px;
    background-color: rgba(0, 0, 0, 0.616);
    border-radius: 1.5rem;
    align-items: center;
    color: variables.$white-palette;
    display: block;
    padding: 1rem;
    .title {
      text-transform: uppercase;
      padding-top: 2rem;
    }
  }
  &-title {
    color: #ffffff;
    text-align: center;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(38, 82, 136, 0.685);
    display: flex;
  }
  &-inputs {
    padding: 3%;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
    .input {
      border: 0px;
      background-color: unset;
      border-bottom: 1px solid;
      font-size: 0.9rem;
      color: variables.$white-palette;
      border-radius: 0px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }
  &-button {
    &:hover {
      color: #a9a9a9;
    }
    &:active {
      color: #a9a9a9;
    }
    &:focus {
      color: #a9a9a9;
    }
    &-secondary {
      background-color: variables.$white-palette;
      color: variables.$secondary;
      border: 0px;
    }
  }
  &-footer {
    margin-top: 1rem;
    p {
      text-align: center;
    }
  }

  &-img {
    color: variables.$white-palette;
    max-width: 2rem;
    max-height: 2rem;
  }

  ::placeholder {
    color: white;
  }
  .label {
    font-size: 15px;
    font-weight: 400;
    align-content: center;
    margin-left: 2%;
    width: 7%;
  }
}

.btn-white {
  background-color: #fff;
  color: #777;
  &::after {
    background-color: #fff;
  }
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animate {
  animation: moveInBottom 1s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (min-width: 821px) {
  .text {
    font-size: 15px;
    font-weight: 400;
    align-content: center;
    margin-left: 45%;
  }
}

/* Para 320px */
@media only screen and (max-width: 340px) and (min-width: 5px) {
  .text {
    font-size: 10px;
    font-weight: 400;
    align-content: center;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
