@use "/src/styles/variables" as *;

.background-discounts {
  background-color: $primary;
  border: 0.5px solid $primary-palette;
  border-radius: 5px;
  color: $primary-palette;
  color: white;
  font: bold;
  padding: 0 5px 0 5px;
  margin-top: 0.5rem;
}
.background-totals {
  margin-top: 0.6rem;
}

.hover-options {
  .principal-content {
    &:hover {
      background-color: #012e63;
      cursor: pointer;
    }
  }

  .secondary-content {
    &:hover {
      background-color: #e8f1ff;
      cursor: pointer;
    }
  }

  .icon-component {
    &:hover {
      color: #012e63;
      cursor: pointer;
    }
  }
}
.card-secondary-content-title {
  background-color: $primary;
  border: 0.5px solid $primary-palette;
  color: $primary-palette;
  padding: 0.3rem;
  width: 100%;
  margin: 0;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;

  span,
  h4,
  h3 {
    color: white;
  }
}

.container-summary {
  width: 100%;
  text-align: end;
}
.payment-container {
  padding-bottom: 1rem;
  display: inline-block;
}

.hover-table-options {
  &:hover {
    color: black;
    cursor: pointer;
  }
}
.format-selects {
  select,
  input {
    border: 1px solid;
    font-size: 0.9rem;
    border-radius: 7px;
  }
  width: 95%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.content-input {
  margin-left: 2.5rem;
  margin-right: 0.5rem;
  max-width: 100%;
}
.content-option {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.content-option2 {
  padding-bottom: 1rem;
}
.content-select {
  padding-top: 1rem;
}
.disabled{
  background-color: #b8c6d6;
}
.disabled:hover{
  background-color: #b8c6d6 !important

}
@media screen and (min-width: 921px) {
  .align-items-right {
    float: right;
    padding-bottom: 1rem;
    width: 25%;
  }
}

@media screen and (min-width: 721px) and (max-width: 920px) {
  .align-items-right {
    float: right;
    padding-bottom: 1rem;
    width: 30%;
  }
}

@media only screen and (max-width: 720px) and (min-width: 401px) {
  .align-items-right {
    float: right;
    padding-bottom: 1rem;
    width: 50%;
  }
}

@media only screen and (max-width: 400px) and (min-width: 5px) {
  .align-items-right {
    float: right;
    padding-bottom: 1rem;
  }
}
