@use "../../styles/variables" as *;

.modal-container {
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/login-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column
}

.modal-container::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(38, 82, 136, 0.685);
  display: flex;
}
.modal-card {
  background-color: rgba(0, 0, 0, 0.616);
  padding: 1rem;
  border-radius: 1rem;
}

.modal-inputs {
  width: 100%;
  margin-bottom: 1rem;
  .input {
    border: 0px;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 1rem;
    color: $white-palette;
    border-radius: 0px;
  }

  ::placeholder {
    color: $white-palette;
  }
  .input,
  select {
    border: 0px;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    color: $white-palette;
    border-radius: 0px;

    option {
      background-color: $blue-option;
      text-align: center;
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}