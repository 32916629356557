.search-user {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.searchInput {
  font-size: 16px !important;
}

.container-buttons{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;
}

.edit-button{
  background: transparent; color: #ff3860; border: solid 0.5px;
}