.bold-text {
  font-weight: bold;
}
.text-centered {
  align-self: center;
}
.button-fixed {
  display: flex;
  justify-content: right;
  position: unset;
  margin-top: 0.7rem;
  right: 1%;
  bottom: 5% !important;
}
