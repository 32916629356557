.searchUser {
  display: flex;
  align-items: center;
}

.Box-hover {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #dbdbdb;
  color: #0774d9;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  min-width: 250px;
  min-height: 60px;
  margin-top: 1rem;
  height: 100px;
  &:hover {
    cursor: pointer;
    border: 1px solid #01234c;
    color: #01234c;
  }
}

.Box {
  display: left;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  color: #0774d9;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  min-width: 250px;
  min-height: 60px;
  height: 100px;
  margin-top: 1rem;
}

.form-item {
  margin: 5px;
}
