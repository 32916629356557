@use "../../styles/variables" as *;

.badged-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badged-icon-container .icon-badge{
  position: absolute;
  top: -10px;
  right: -10px;
}
  