.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox_input {
  display: none;
}

.checkbox_box {
  width: 17px;
  height: 17px;
  border: 2px solid #0774d9;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrink: 0;
  transition: background 0.15s, border-color 0.15s;
}

.checkbox_box::after {
  content: "\2714";
  color: #fff;
  transform: scale(0);
  transition: transform 0.15s;
}

.checkbox_input:checked + .checkbox_box {
  background: #0074d9;
  border-color: #0074d9;
}

.checkbox_input:checked + .checkbox_box::after {
  transform: scale(1);
}

.checkbox_label {
  color: #0774d9;
  background: rgb(0, 0, 0, 0.1);
  padding: 5px 5px 5px 5px;
  font-size: 1em;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox_label:hover {
  cursor: pointer;
  color: #01234c;
}
