@use '../../styles/variables' as *;

.scheduled-bill {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 6px solid $primary;
  border-radius: 3px;
  &__detail-text {
    margin: auto 0px;
    font-weight: normal;
    
    &--bold {
      font-weight: bold;
    }
  }
}

.scheduledbill-flex {
  display: flex;
  gap: 10px;
}