@use "../../styles/variables" as *;

.groups-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0rem 1rem 2rem 0rem;
  button {
    margin: 1rem 0px 0px 0rem;
  }
}

.groups-content {
  display: flex;
  .group_information {
    width: 100%;
  }
}

.icon-edit {
  margin-top: -0.5rem;
  color: $primary;
  position: absolute;
  right: 2rem !important;
}

.card-content-group {
  background-color: #f5f9ff;
  border: 0.5px solid $primary-palette;
  border-radius: 5px;
  color: $primary-palette;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 100%;
  max-height: 100%;
  &:hover {
    background: #e5eefc;
  }
}

.icon-cancel {
  margin-top: -0.5rem;
  color: $primary;
  position: absolute;
  right: -0.1rem;
}

.hover-table-options {
  &:hover {
    color: black;
    cursor: pointer;
  }
}
