@use "../../styles/variables" as *;

.input-password {
    display: flex;
    align-items: center;
    width: 100%;
    .input {
      width: 100%;
    }
    .icon {
      cursor: pointer;
      position: absolute;
      right: 25px;
    }
  }
