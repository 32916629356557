.button-fixed-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 600px) {
    .button-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}
