.card-date {
  padding: 1rem;
}

.container-date {
  display: flex;
}

.input-date {
  max-width: 200px;
}

.container-input-date-month {
  margin-right: 2rem;
}

.month-datepicker {
  padding-left: 0.5rem;
  font-size: 16px;
  border: solid 1px #01234c;
  border-radius: 0.7rem !important;
  min-width: 200px;
  min-height: 36px;
  max-width: 300px;
  text-transform: capitalize;
  margin-right: 50px;
}

.container-search-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-search-date {
  background-color: #01234c;
  min-width: 150px;
  min-height: 50px;
  margin-top: 1rem;
  &:focus,
  &:hover {
    color: white;
  }
}

.card-store {
  background-color: #dbdbdb;
  border: 1px solid #01234c;
  border-radius: 5px;
  min-width: 250px;
  min-height: 60px;
  margin-top: 1rem;
  &:hover {
    cursor: pointer;
    border: 1px solid #dbdbdb;
    background-color: #cfcfcf;
  }
}

.card-header-store {
  background-color: #01234c;
  border-radius: 5px;
  padding-left: 0.4rem;
}

.card-body-store {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
}

.card-header-text {
  color: #fff;
  font-weight: 700;
}

.card-store-icone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  height: 100%;
  padding-left: 1rem;
}

.card-store-information {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
}

.container-all-bills {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.font-icon-download {
  cursor: pointer;
}
