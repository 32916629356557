.text-center {
  text-align: center !important;
}
.w-30 {
  width: 30%;

  @media only screen and (max-width: 820px) and (min-width: 5px) {
    width: 70%;
  }
}
.w-40 {
  width: 40%;

  @media only screen and (max-width: 820px) and (min-width: 5px) {
    width: 70%;
  }
}
.d-flex {
  display: flex !important;
}
.justify-content-center {
  justify-content: center;
}

.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.ml-1 {
  margin-left: 1rem;
}
.p-1 {
  padding: 1rem;
}
.fw-bolder {
  font-weight: bolder;
}
