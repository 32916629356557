@use '../../styles/variables' as *;
.bill{
    &__sub-header{
        padding: 5px;
        background-color:$blue-med;
        color: $text_primary;
        margin-bottom:10px;
    }
    &__print-button{
        padding: 20px 40px;
    }
    &__button-container{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
    }
}

@media screen and (max-width: 769px) {
.bill {
    &__button-container {
        flex-direction: column;
    }
}

}
