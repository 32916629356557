@use "../../styles/variables" as *;

.option-item {
  color: $primary-palette;
  padding: 1rem;
  width: 100%;
}

.option-item:hover {
  background-color: lighten($primary-palette, 70%);
}

.option-container {
  display: flex;
}

.option-content-left {
  justify-content: flex-start;
  text-align: flex-start;
  width: 90%;
}
