@use "/src/styles/variables" as *;

.notification-card {
  position: relative;
  border-radius: 15px;
  min-width: 300px;
  max-width: 400px;
  min-height: 200px;
  max-height: 250px;
  transition-duration: 250ms;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notification-card.is-not-opened {
  border: 2px solid rgba(1, 35, 76);
  color: rgba(1, 35, 76);
}
.notification-card.is-clickable:hover {
  box-shadow: 0px 0px 8px .1px rgba(0, 0, 0, 0.329);
}
.notification-card.is-clickable.is-not-opened:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.329);
}
.notification-card.is-clickable:active {
  background-color: #edf2fa;
  box-shadow: 0px 0px 6px .1px rgba(0, 0, 0, 0.329);
}

.notification-header {
  position: absolute;
  text-align: center;
  width: 96%;
  top: 3px;
  text-decoration-line: underline;
}

.notification-content {
  max-height: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.notification-footer {
  position: absolute;
  bottom: 3px;
}
