@use "../../styles/variables" as *;

.Proforma {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  select {
    border: 0.1rem solid $primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    height: 40px;
  }
  &__card-container {
    display: flex;
    direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__card {
    padding: 50px;
    background-color: #f5f9ff;
  }
  &__header-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__dater-picker {
    flex-wrap: nowrap;
  }
}

.proforma-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 10px;
}

.no-results {
  text-align: center;
  background: #17375e;
  margin: 1%;
}

.no-results-text {
  color: white;
}

@media screen and (max-width: 800px) {
  .proforma-grid {
    grid-template-columns: auto auto;
  }
}
