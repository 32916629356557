@use "../../styles/variables" as *;
.place-selector {
  align-self: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  border: solid 1px #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    background-color: darken($primary, 30%);
  }
  &__image {
    aspect-ratio: 1/1;
  }
  &__modal-container {
    height: 600px;
  }
}
