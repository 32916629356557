.css-6n4ajs-menu .css-yt9ioa-option,
.css-6n4ajs-menu .css-1n7v3ny-option,
.css-6n4ajs-menu .css-9gakcf-option {
  width: 100% !important;
}

.title-search {
  padding: 0 0.5rem;
  font-size: 1.2em;
}

.add-client-container {
  box-shadow: 0px 1px 2px 0px #999;
  border-radius: 0.2rem;
  padding: 0.5rem;
  margin-top: 1.5rem;
}

.header-containers {
  width: 50%;
  min-width: 300px;
  padding: 0.5rem;
}

.right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .right-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
