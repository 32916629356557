.box-select {
  border: 0.5px solid #01234c;
  padding: 1rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.warning{
  margin: 3px;
  background: #ff3860;
  border-radius: 0.4rem;
}
