@use "../../styles/variables" as *;
.products-buttons {
  background-color: transparent;
  color: $primary-palette;
  border: $white-palette 0rem solid;
}

.option-item {
  color: $primary-palette;
  padding: 1rem;
  width: 100%;
}

.option-item:hover{
  background-color: lighten($primary-palette,70%);
}

.option-container {
  display: flex;
}

.product-img-option {
  justify-content: flex-start;
  display: block;
  height: 90px;
  width: 90px;
}

.option-content-left {
  justify-content: flex-start;
  text-align: flex-start;
  width: 90%;
}

.bottom-sheet {
  background-color: #f5f9ff;
  margin-bottom: 100px;
}
.add-button {
  width: 100%;
  padding: 3%;
  margin: 5% 0%;
  &--hidden {
    display: none;
  }
}

.sheetHeader {
  border: 1px solid f5f9ff;
  border-radius: 35px;
  width: 55px;
  height: 55px;
  background-color: #f5f9ff;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  .icon-header {
    text-align: center;
    vertical-align: middle;
    color: $primary-palette;
  }
}

.sheetHeader-quantity {
  border: 1px solid $primary-palette;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: $primary-palette;
  position: absolute;
  top: -5px;
  left: 70%;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  .quantity-text {
    color: white;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
  }
}

[data-rsbs-header]:before {
  background-color: transparent;
}

[data-rsbs-header] {
  flex-shrink: 0;
  cursor: pointer;
  padding: 17px;
  background-color: #f5f9ff;
}

[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
  overflow: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  background-color: #f5f9ff;
}

.open-sidebar{
  margin-left: 295px;
}

.select-client-button {
  margin-top: 0rem;
  color: $primary;
  position: absolute;
  right: 1rem;
}

.css-6n4ajs-menu .css-yt9ioa-option,
.css-6n4ajs-menu .css-1n7v3ny-option,
.css-6n4ajs-menu .css-9gakcf-option {
  width: 33% !important;
}

@media screen and (max-width: 1000px) {
  .css-6n4ajs-menu .css-yt9ioa-option,
  .css-6n4ajs-menu .css-1n7v3ny-option,
  .css-6n4ajs-menu .css-9gakcf-option {
    width: 50% !important;
  }
}

@media screen and (max-width: 600px) {
  .css-6n4ajs-menu .css-yt9ioa-option,
  .css-6n4ajs-menu .css-1n7v3ny-option,
  .css-6n4ajs-menu .css-9gakcf-option {
    width: 100% !important;
  }
}
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 4;
}
