@use "../../styles/variables" as *;
.places {
  &-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    position: relative;
  }
  &-loading {
    height: 100%;
    width: 100%;
    bottom: 5;
    position: absolute;
  }
  &__password-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    &-off {
      color: $secondary-palette;
    }
    &-on {
      color: $primary-palette;
    }
  }
}

.cancel-button{
  background: transparent; color: #ff3860; border: solid 0.5px;
  margin-right: 20px;
}