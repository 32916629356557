@use "../../styles/variables" as *;

.selector {
  background-color: transparent;
  padding: 10rem;
  padding: 5%;
  border: 0px;
  background-color: unset;
  border-bottom: 1px solid;
  font-size: 1rem;
  color: $white-palette;
  border-radius: 0px;
}
