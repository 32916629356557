@use "../../styles/variables" as *;

.img-gmail {
  height: 30px;
  width: 70px;
}

.img-zoho {
  height: 30px;
  width: 80px;
}

zoho-icon {
  height: 50px;
  width: 60px;
}

.zoho-logout {
  display: flex;
  justify-content: flex-end;
}

.profile-page-container {
  select {
    border: 0.1rem solid $primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    height: 37px;
  }
}
.photo-content {
  margin-bottom: 1rem;
  border: 2px solid $primary-palette;
  border-radius: 1rem;
  color: $primary-palette;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  justify-content: center;
  padding-bottom: 0.5rem;
  overflow: hidden;
}
.profile-page-preview {
  width: 100px;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 1rem;
  justify-content: center;
  border: 0.2rem solid $secondary-palette;
}

.profile-page-photo {
  margin: 0.2rem;
  border-radius: 1rem;
}

.profile-page-select-details {
  border: 0.1rem solid $primary;
  display: inline-block;
  border-radius: 0.5rem;
  height: 41px;
}

.profile-page-button {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.profile-page-title {
  justify-content: center;
  display: flex;
  margin-bottom: 0.5rem;
}

.icon-edit {
  margin-top: -0.5rem;
  color: $primary;
  position: absolute;
  right: -0.1rem;
}

.input-file {
  display: none;
}
.dropzone {
  background: $white-ter;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 20px;
  cursor: pointer;
  height: 200px;
  width: 100%;
  border: 2px dashed $primary;
  outline: none;
}
.dropzoneReject {
  border: 2px dashed rgb(179, 31, 31);
}
.dropzoneAccept {
  border: 2px dashed rgb(49, 179, 49);
}

.card-title {
  font-size: 2rem;
}

.button-select {
  margin: 1rem;
}

.btn-animate {
  animation: moveInBottom 1s ease-out;
  animation-fill-mode: backwards;
}

.body-card {
  height: 75%;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.card-header-button {
  margin-top: 1rem;
  text-align: right;
  display: flex;
  justify-content: center;
}

.card-checkbox {
  margin-left: 5%;
}

.content-invoice {
  display: flex;
  justify-content: space-between;
  button:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.buttons-zoho-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  flex-wrap: wrap;
}

.zoho-card {
  display: flex;
  background: #01234c;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 300px;
  border-radius: 10px;

  .img-gmail-expense {
    height: 30px;
    width: 70px;
  }

  .img-zoho-expense {
    height: 30px;
    width: 80px;
  }
}

.hide-buttons-zoho-more {
  display: none;
}

.progress-bar {
  margin-top: 10px;
}

.zoho-number-page {
  display: flex;
  background: #01234c;
  max-width: 25px;
  max-height: 25px;
  border-radius: 4px;
  justify-content: center;
  color: #fff;
  width: 100%;
}

.container-switch-email {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.switch-email {
  min-width: 200px;
  max-width: 350px;
}

.btn-switch {
  display: flex;
  border-radius: 10px;
  min-width: 200px;
  max-width: 300px;
  border: solid 2px #d9d9d9 !important;
  padding: 0.5rem;
  p {
    padding-left: 0.5rem;
    color: #000000 !important;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(157, 157, 157, 0.15);
  }
}

.date-container {
  display: flex;
}
