.document-search {
  &__header {
    width: 100%;
    gap: 30px;
  }
  &__button-container {
    display: flex;
    flex-direction: row;
    width: 20%;
    align-items: flex-end;
  }
  &__search-button {
    width: 100%;
  }
}

.active {
  background-color: #01234c;
  color: white !important;
}
.active:hover {
  background-color: #003679 !important;
  color: white !important;
}
.tab:hover {
  background-color: #01234cb6 !important;
  color: white !important;
}
.cursor-pointer {
  cursor: pointer;

  label {
    cursor: pointer;
  }
}
.mb-5 {
  margin-bottom: 2rem;
}

@media screen and (max-width: 900px) {
  .document-search__header-container {
    display: inline;
    background-color: red;
  }

  .document-search__header {
    margin-bottom: 32px;
  }
}