@use "../../styles/variables" as *;

.notifications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.notifications-container > *{
  margin: 10px;
}
