@use "../../styles/variables" as *;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
.home-card {
  color: $primary-palette;
  border: 0.1rem solid $primary-palette;
  border-radius: 0.5rem;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  background-color: white;
}

.home-icon {
  margin-top: 1rem;
  width: 4rem !important;
  height: 4rem !important;
}
