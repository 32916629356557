.product-img {
  width: 60px;
  margin: 0.2rem;
}
.invetories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: inherit;
  gap: 10px;
  justify-content: center;
  &__invetory {
    width: auto;
    flex-grow: 1;
  }
}
.selected-place {
  background-color: darken(#f5f9ff, 10%);
}
.search-bar {
  margin: 10px 0px;
}
