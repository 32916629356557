@use "../../styles/variables" as variables;

.password-forgot {
  align-items: center;
  background-image: url(../../assets/login-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1%;
  width: 100%;
  height: 40vh;
  position: relative;
  margin: 0%;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(38, 82, 136, 0.685);
    display: flex;
  }
  &-title {
    color: variables.$black-bis;
    text-align: center;
    font-weight: bold;
  }
  &-input {
    padding: 3%;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
    .input {
      border: 0px;
      background-color: rgba(38, 82, 136, 0.685);
      border-bottom: 1px solid;
      font-size: 0.9rem;
      color: variables.$white-palette;
      border-radius: 4px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px rgba(38, 82, 136, 0.685) inset !important;
      box-shadow: 0 0 0 30px rgba(38, 82, 136, 0.685) inset !important;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: rgb(250, 246, 246);
    }
  }
}
