.account-card {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100px;
  align-items: flex-end;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 10px;
  .img-zoho {
    height: 30px;
    width: 80px;
    margin-left: 4px;
    margin-right: 20px;
  }
  .img-gmail {
    height: 40px;
    width: 80px;
    margin-left: 4px;
    margin-right: 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(157, 157, 157, 0.15);
  }
}

.account-card-selected {
  background-color: rgba(157, 157, 157, 0.15);
}

.account-card-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.information-place-container {
  position: absolute;
  left: 130px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 170px;
  border-radius: 10px;
  p {
    font-weight: 700;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(157, 157, 157, 0.1);
  }
}

.information-email-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  div {
    height: 48px;
  }
}

.account-email-container {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.information-place-none {
  display: none;
}
