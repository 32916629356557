@use "../../styles/variables" as *;

.date-picker {
  outline: none;
  width: 219px;
  height: 46px;
  font-size: 20px;
  text-transform: capitalize; 
  border: solid 2px #d9d9d9;
  background-color: #f4f4f4;
  border-radius: 0.6rem;
  transition-duration: 80ms;
}
.date-picker:hover {
  border: solid 2px $grey-light;
}
.date-picker:focus {
  border: solid 2px $grey-light;
}
