@use "../../styles/variables" as *;

.table-section {
  box-shadow: 1px 2px rgba(0, 0, 0, 0.09);
  background-color: white;
  padding: 1.5rem;
  border-radius: 0 20px 20px 20px;
  .data-table-container {
    position: relative;
    // font-size: 0.8rem;
    margin-bottom: 1.5rem;
    &.is-fixed-width {
      max-width: 100%;
    }
    table {
      width: 100%;
      td,
      th {
        cursor: pointer;
        white-space: pre-wrap;
        @media screen and (min-width: 800px) {
          &:not(.note) {
            white-space: nowrap;
          }
        }
      }
      thead {
        border-bottom: solid 4px $primary;
        tr {
          
          td:first-child,
          th:first-child {
            width: 2rem;
          }
          th {
            font-weight: normal;
            letter-spacing: 1px;
            border: none;
            background: transparent;
            color: $black;
            padding: 0.25em 1em;
          }
        }
        
        
      }
      tbody {
        color: $grey;
        tr {
          td {
            border: none;
            border-bottom: 2px solid $background;
            padding: 2em 1em 1em 1em !important;
          }
          .right {
            text-align: right;
          }
          .left {
            text-align: left;
          }
        }
        tr:nth-child(even) {
          background-color: lighten($primary, 80%) 
        } tr:nth-child(even):hover {
          background-color: lighten($primary, 70%) 
        }
        tr:nth-child(odd):hover{
          background-color: darken(white,10%)
        }
      }
    }
    @media only screen and (max-width: 800px) {
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead tr {
        display: flex;
      }

      thead tr th:not(#select-all-bills) {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border: 1px solid #ccc;
        // background-color:aqua
      }
     

      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: right !important;
        justify-content: end !important;
      }

      td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
      }
      td:before {
        content: attr(data-title);
      }
    }
    pre {
      padding: 0;
      background: none;
    }
  }
}