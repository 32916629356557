@use "../../../../styles/variables" as *;

.favorite-container {
  width: 100%;
  height: 100%;
}

.favorite-card {
  background-color: #f5f9ff;
  border: 0.5px solid $primary-palette;
  border-radius: 7px;
  padding: 1rem;
  color: $primary-palette;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
  width: 100%;
  height: 100%;
}
