.send-bill-screen{
    display: flex;
    flex-direction: column;
    &__search-container{
        display:flex;
        flex-direction:row;
        gap:10px
        
    }
    &__actions-container{
        display:flex;
        flex-direction:column;
        gap:10px
    }
    &__search-button{
        height:auto;
    }
    &__tittle{
        width:auto;
        text-align:center
    }
    &__receiver-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    &__table-row{
        cursor: pointer;
    }
    &__client-container{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 5px;
        justify-content:stretch;
        max-height:200px;
        overflow-y: auto;
        .notification {
            cursor: pointer;
            &:hover {
                outline: 1px solid gray;
            }
        }
    }
}

.send-bill-item{
    background-color: #f5f5f5;
}