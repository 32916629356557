.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.Container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cart-box {
  display: flex;
  justify-content: space-between;
  margin-right: 15%;
}

.select-role {
  min-width: 205px;
}

.edit-button{
  background: transparent; color: #ff3860; border: solid 0.5px;
}