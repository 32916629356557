.emmited-document {
  gap: 10px;
  display: flex;
  flex-direction: column;

  &__container {
    padding: 1rem;
  }
  &__header {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  &__header-text {
    min-width: fit-content;
  }
  &__datePicker {
    min-width: 200px;
    min-height: 40px;
    max-width: 300px;
    font-size: 16px;
    padding-left: 0.5rem;
    text-transform: capitalize;
  }
}
