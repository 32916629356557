@use "../../styles/variables" as *;

.control-icon {
  width: 22px;
  height: fit-content;
  margin-left: 10px;
}

.option-icon {
  width: 20px;
  margin-right: 10px;
}
