.user-footer {
  align-items: center;
}

.picture-container {
  width: 57px;
  height: 64px !important;
}

.headerButtons {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  flex-wrap: wrap;
  padding-left: 1rem;
  .button{
    margin-bottom: 0.5rem;
  }
}
