.delete-document-container {
  position: absolute;
  right: 5px;
  top: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
