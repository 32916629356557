.document-search {
  &__header {
    width: 100%;
    gap: 30px;
  }
  &__button-container {
    display: flex;
    flex-direction: row;
    width: 20%;
    align-items: flex-end;
  }
  &__search-button {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .document-search__header-container {
    display: inline;
    background-color: red;
  }
  .document-search__header {
    margin-bottom: 16px;
  }
}