@use "../../../../styles/variables" as *;

.list-container {
  height: 40%;
}
.button-footer {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f9ff;

  button {
    margin: 1.5rem;
    width: 100%;
    height: 50%;
    border: 0.5px solid $primary-palette;
    border-radius: 7px !important;
  }
}
.receiver-info {
  margin-bottom: 1rem;
}

.container-info-group {
  margin-top: 1rem;
}
